<template>
  <TransitionRoot :show="open" as="template" @after-leave="query = ''" appear>
    <Dialog as="div" class="relative z-10" @close="emit('closeSearch')">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-[--xl-round] bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all !outline-none"
          >
            <Combobox>
              <div
                class="relative ring-0 focus:ring-offset-0 focus:ring-transparent border-transparent focus:border-transparent !outline-none"
              >
                <MagnifyingGlassIcon
                  class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400 ring-0 focus:ring-offset-0 focus:ring-transparent border-transparent focus:border-transparent !outline-none"
                  aria-hidden="true"
                />
                <input
                  @keydown.enter="search()"
                  v-model="searchQuery"
                  class="h-12 w-full bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 sm:text-sm ring-0 focus:ring-offset-0 focus:ring-transparent border-transparent focus:border-transparent !outline-none"
                  placeholder="Event Suche..."
                />
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps(["open"]);
const emit = defineEmits(["closeSearch"]);

import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";

import {
  Combobox,
  ComboboxInput,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

const searchQuery = ref("");
const router = useRouter();

function search() {
  router.push({
    path: "/eventkalender",
    query: { searchQuery: searchQuery.value },
  });

  emit("closeSearch");
}
</script>
